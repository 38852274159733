import { LanguageService } from 'src/app/shared/services/language.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ILogin } from 'src/app/interfaces/ilogin';
import { IUser } from 'src/app/interfaces/iuser';
import { environment } from 'src/environments/environment';
import { CryptoSharkService } from './crypto-shark.service';
import { HttpService } from './http.service';
import * as moment from 'moment-timezone'

@Injectable({
  providedIn: 'root',
})
/**
 * Servicio administrador de sesión de usuario
 */
export class UserService {
  private _login: ILogin = {}
  private _user: IUser = {}
  public mensaje: string = '';
  
  constructor(private httpService: HttpService, private router: Router, private cryptoService: CryptoSharkService,private languageService: LanguageService) { }

  /**
   * Guardar los datos del usuario
   * @param login Credenciales del usuario que inició sesión
   */
  signInCurrentUser(login: ILogin) {
    localStorage.setItem('ldt', JSON.stringify(login));
  }

  /**
   * Obtener datos de la sesión del usuario
   */
  get login() {
    return this._login
  }

  /**
   * Obtener datos del usuario
   */
  get user() {
    return this._user
  } 

  /**
   * Obtener datos de sesión del usuario validUser()
   * @param returnUrl Url a la que regresará una vez que haya iniciado sesión
   */
  getCurrentUser(returnUrl: string): Promise<boolean> {
    return new Promise((resolve) => {
      this._login = JSON.parse(localStorage.getItem(`ldt`));
      
      if (!this._login?.isRegister) {
        this.router.navigate(['/login'], {queryParams: { returnUrl } });
        resolve(false);
      }
      environment.apiUrl = this._login.company.server;
      
      this.httpService.get(`api/login`).subscribe((response) => {

        this._login.company.key = response.key; //<-- ENCRYPT REQUESTS 
        this._login.company.dk = this.cryptoService.decrypt(response.datakey,response.key);//<-- DECRYPT RESPONSES
        if(this._login.company.serverMedia) this._login.company.serverMedia = this._login.company.serverMedia + `${response?.companyName}/assets/`;
        this._user.email = this.cryptoService.decrypt(response.email, this._login.company.key)
        this._user.name = this.cryptoService.decrypt(response.name, this._login.company.key)
        this._user.nickName = this.cryptoService.decrypt(response.nickname, this._login.company.key)
        this._user.roleName = response.roleName
        this._user.metrics_data = response.metrics;
        this._user.vacancy = response.vacancy;
        this._user.reports = response.reports;
        this._user.checkWithPhoto = response.checkWithPhoto;
        this._user.editChecks = response.editChecks;
        this._user.permissions = this.Permissions;//{ ...response.permissions }
        this._user.companyCountry = response.companyCountry;
        this._user.notCheckDigitalCredential = response.notCheckDigitalCredential;
        this._user.payrollsType = response.payrollsType;
        this._user.prepayrollsType = response.prepayrollsType;
        this._user.layoutsPayroll = response.layoutsPayroll;
        this._user.layoutsPrepayroll = response.layoutsPrepayroll;
        this._user.startWeek = response.startWeek || 1;
        this._user.doyMoment = response.doyMoment || 0;
        this._user.useUniformPackages = response.useUniformPackages || false;
        this._user.calendarType = response.calendarType || "SEMANAL";
        this._user.multiBusinessName = response.multiBusinessName || false;
        this._user.vacationsConfig = response.vacationsConfig || null;
        this._user.basicImport = response.basicImport || false;
        this._user.uniqueCSVpayroll = response.uniqueCSVpayroll || false;
        this._user.checkerBasicImport = response.checkerBasicImport;
        
        this.languageService.setLanguageForUser(response.language ?? 'MX')

        moment.tz.setDefault(response?.timeZone || "America/Tijuana");
        
        resolve(true)
      });
    })
  }
  

  /**
   * Cerrar sesión
   * Borrar los datos de sesión del usuario
   */
  signOutCurrentUser() {
    // environment.apiUrl = 'http://10.10.2.34:9000/'
    environment.apiUrl = environment.urlAdmin;
    localStorage.removeItem('ldt')
    this.router.navigate(['/login'])
  }

  get Permissions(){
    return {
        "analitics": {
            "access": true
        },
        "payroll": {
            "access": true
        },
        "letters": {
            "access": true
        },
        "checker": {
            "access": true
        },
        "checker-bus": {
            "access": true
        },
        "cafeteria": {
            "access": true
        },
        "credentials": {
            "access": true
        },
        "employees": {
            "access": true
        },
        "surveys": {
            "access": true
        },
        "inventory": {
            "access": true
        },
        "metrics": {
            "access": true
        },
        "news": {
            "access": true
        },
        "receipts": {
            "access": true
        },
        "reports": {
            "access": true
        },
        "routes": {
            "access": true
        },
        "supports": {
            "access": true
        },
        "uniforms": {
            "access": true
        },
        "users": {
            "access": false
        },
        "vacations": {
            "access": true
        },
        "companies": {
          "access": true
      },
        "adelantos": {
            "access": true
        }
    };
  }
}
