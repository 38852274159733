import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'namemodule'
})
export class NamemodulePipe implements PipeTransform {

  public modules: any = {
    "payroll":"Carga de Información",
    "employees":"Empleados",
    "analitics":"Analíticos",
    "letters":"Cartas",
    "cafeteria":"Comedor",
    "credentials":"Credenciales",
    "surveys":"Encuestas",
    "metrics":"Métricos",
    "news":"Noticias",
    "receipts":"Recibos",
    "reports":"Reportes",
    "routes":"Rutas",
    "uniforms":"Uniformes",
    "vacations":"Vacaciones",
    "checker":"Checador",
    "checker-bus":"Checador BUS",
    "inventory":"Inventario",
    "users":"Usuarios",
    "supports":"Soporte",
    "":"Informacion no disponible"
  }

  transform(value: string): string {
    //console.log(value, this.modules['analitics'])
    return value ? this.modules[value] : "";
  }

}
