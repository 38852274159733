import { Routes } from '@angular/router';
import { RoleGuard } from '../services/role-guard.service';

//Route for content layout without sidebar, navbar and footer for pages like Login, Registration etc...
export const Pages_Routes: Routes = [
    {
        path: '',
        canActivate: [RoleGuard],
       // loadChildren: () => import('../../pages/analitics/analitics.module').then(m => m.AnaliticsModule)
       loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'analitics',
        canActivate: [RoleGuard],
        data: { role: 'analitics'},
        //loadChildren: () => import('../../pages/analitics/analitics.module').then(m => m.AnaliticsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'metrics',
        canActivate: [RoleGuard],
        data: { role: 'metrics'},
        //loadChildren: () => import('../../pages/metrics/metrics.module').then(m => m.MetricsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'employees',
        canActivate: [RoleGuard],
        data: { role: 'employees'},
        loadChildren: () => import('../../pages/employees/employees.module').then(m => m.EmployeesModule)
        //loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'cafeteria',
        canActivate: [RoleGuard],
        data: { role: 'cafeteria'},
        //loadChildren: () => import('../../pages/cafeteria/cafeteria.module').then(m => m.CafeteriaModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'news',
        canActivate: [RoleGuard],
        data: { role: 'news'},
        //loadChildren: () => import('../../pages/news/news.module').then(m => m.NewsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'letters',
        canActivate: [RoleGuard],
        data: { role: 'letters'},
        //loadChildren: () => import('../../pages/letters/letters.module').then(m => m.LettersModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'credentials',
        canActivate: [RoleGuard],
        data: { role: 'credentials'},
        //loadChildren: () => import('../../pages/credentials/credentials.module').then(m => m.CredentialsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'users',
        canActivate: [RoleGuard],
        data: { role: 'users'},
        loadChildren: () => import('../../pages/users/users.module').then(m => m.UsersModule)
        //loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'payroll',
        canActivate: [RoleGuard],
        data: { role: 'payroll'},
        loadChildren: () => import('../../pages/payroll/payroll.module').then(m => m.PayrollModule)
        //loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'uniforms',
        canActivate: [RoleGuard],
        data: { role: 'uniforms'},
        //loadChildren: () => import('../../pages/uniforms/uniforms.module').then(m => m.UniformsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'receipts',
        canActivate: [RoleGuard],
        data: { role: 'receipts'},
        //loadChildren: () => import('../../pages/receipts/receipts.module').then(m => m.ReceiptsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'checker',
        canActivate: [RoleGuard],
        data: { role: 'checker'},
        //loadChildren: () => import('../../pages/checker/checker.module').then(m => m.CheckerModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'checker-bus',
        canActivate: [RoleGuard],
        data: { role: 'checker-bus'},
        //loadChildren: () => import('../../pages/checker-bus/checker-bus.module').then(m => m.CheckerBusModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'inventory',
        canActivate: [RoleGuard],
        data: { role: 'inventory'},
        //loadChildren: () => import('../../pages/inventory/inventory.module').then(m => m.InventoryModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'surveys',
        canActivate: [RoleGuard],
        data: { role: 'surveys'},
        //loadChildren: () => import('../../pages/surveys/surveys.module').then(m => m.SurveysModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'supports',
        canActivate: [RoleGuard],
        data: { role: 'supports'},
        loadChildren: () => import('../../pages/supports/supports.module').then(m => m.SupportsModule)
        //loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },  
    {
        path: 'reports',
        canActivate: [RoleGuard],
        data: { role: 'reports'},
        loadChildren: () => import('../../pages/reports/reports.module').then(m => m.ReportsModule)
        //loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },
    {
        path: 'routes',
        canActivate: [RoleGuard],
        data: { role: 'routes'},
        //loadChildren: () => import('../../pages/routes/routes.module').then(m => m.RoutesModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },    
    {
        path: 'vacations',
        canActivate: [RoleGuard],
        data: { role: 'vacations'},
        //loadChildren: () => import('../../pages/vacations/vacations.module').then(m => m.VacationsModule)
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    }, 
    {
        path: 'information',
        canActivate: [RoleGuard],
        data: { role: 'information'},
        loadChildren: () => import('../../pages/information/information.module').then(m => m.InformationModule)
    },  
    {
        path: 'companies',
        canActivate: [RoleGuard],
        data: { role: 'companies'},
        loadChildren: () => import('../../pages/companies/companies.module').then(m => m.CompaniesModule)
    },     
    {
        path: 'adelantos',
        canActivate: [RoleGuard],
        data: { role: 'adelantos'},
        loadChildren: () => import('../../pages/advances/advances.module').then(m => m.AdvancesModule)
    },                    
]